import moment from 'moment'
export function getDateFormatString (data) {
  var date = moment(data).format('YYYY-MM-DD').toString()
  return date
}

export function getTimeFormatString (data) {
  var time = moment(data).format('HH:mm').toString()
  return time
}

export const jsCoreDateCreator = (dateString) => {
  // dateString HAS to be in this format "YYYY-MM-DD HH:MM:SS"
  let dateParam = dateString.split(/[\s-:]/);
  dateParam[1] = (parseInt(dateParam[1], 10) - 1).toString();
  return new Date(...dateParam);
};

export function getDateAndTimeUtcFormat (date, time) {
  var dateAndTime = moment.utc(moment(date).format('YY-MM-DD').toString() + ' ' + time).toString()
  return dateAndTime
}

export function getDateAndTimeLocal (date, time) {
  return moment(moment(date).format('YY-MM-DD').toString() + ' ' + time).toString()
}

export function getTodaysDate () {
  return moment().format('YYYY-MM-DD').toString()
}

export function minutesToAddFromTotalTime (fromDate, fromTime, endDate, toTime) {
  let startTime = new Date(fromDate + ' ' + fromTime)
  let endTime = new Date(endDate + ' ' + toTime)
  let totalWorkingHours = startTime - endTime
  let minutesToAdd = Math.abs(totalWorkingHours / (1000 * 60 * 2))
  return [minutesToAdd, startTime, endTime]
}
export function subtractOneDay (date) {
  let data = moment(date).subtract(1, 'days').format('YYYY-MM-DD').toString()
  return data
}

export function addOneDay (date) {
  let data = moment(date).add(1, 'days').format('YYYY-MM-DD').toString()
  return data
}

export function getDayAccordingToLimits (limit) {
  return new Promise((resolve, reject) => {
    console.log('reject', reject)
    let currentDate = new Date()
    let currentDate1 = new Date()
    let y = ''
    let m = ''
    let obj = {
      startDate: currentDate,
      endDate: currentDate1
    }
    const first = currentDate.getDate() - currentDate.getDay()
    const d = new Date()
    switch (limit) {
      case 'today':
        break
      case 'yesterday':
        obj.startDate = new Date(currentDate.setDate(currentDate.getDate() - 1))
        obj.endDate = new Date(currentDate)
        break
      case 'thisWeek':
        obj.startDate = new Date(currentDate.setDate(first))
        console.log('this.week', first)
        obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 6))
        break
      case 'pre7Days':
        y = currentDate.setDate(currentDate.getDate() - 6)
        obj.startDate = new Date(currentDate.setDate(first))
        break
      case '4Days':
        obj.startDate = new Date(currentDate.setDate(first))
        console.log('this.week', first)
        obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 3))
      break
      case 'lastWeek':
        obj.endDate = new Date(d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000))
        obj.startDate = new Date(d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000))
        break
      case 'thisMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, m, 1)
        obj.endDate = new Date(y, m + 1, 0)
        break
      case 'lastMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, (m - 1), 1)
        obj.endDate = new Date(y, m, 0)
        break
      case 'next30Days':
        y = currentDate1.setDate(currentDate.getDate() + 29)
        obj.endDate = new Date(y)
        break
      case 'next60Days':
        y = currentDate1.setDate(currentDate.getDate() + 59)
        obj.endDate = new Date(y)
        break
      case '15Days':
        y = currentDate.setDate(currentDate.getDate() - 14)
        obj.startDate = new Date(y)
        break
      case 'last60Days':
        y = currentDate.setDate(currentDate.getDate() - 59)
        obj.startDate = new Date(y)
        break
      case 'last30Days':
        y = currentDate.setDate(currentDate.getDate() - 29)
        obj.startDate = new Date(y)
        break
      case 'last90Days':
        y = currentDate.setDate(currentDate.getDate() - 89)
        obj.startDate = new Date(y)
        break
      case 'last120Days':
        y = currentDate.setDate(currentDate.getDate() - 119)
        obj.startDate = new Date(y)
        break
      case 'last180Days':
        y = currentDate.setDate(currentDate.getDate() - 179)
        obj.startDate = new Date(y)
        break
    }
    obj.startDate = new Date(obj.startDate.setHours(0, 0, 0, 0)).toUTCString()
    obj.endDate = new Date(obj.endDate.setHours(23, 59, 59, 999)).toUTCString()
    resolve(obj)
  })
}
export function getDisplayText (startDate, endDate) {
  if (startDate !== '' && endDate !== '') {
    let temp = ''
    const sfDate = moment(new Date(startDate)).format('YYYY-MM-DD');
    const eDate = moment(new Date(endDate)).format('YYYY-MM-DD');
    if (sfDate === eDate) {
      temp = getStringDate(startDate, 'MMM D');
      temp += ` ${moment(new Date(startDate)).format(
        'hh:mm a',
      )} - ${moment(new Date(endDate)).format('hh:mm a')}`;
    } else {
      temp = `${getStringDate(startDate, 'MMM D')} ${moment(
        new Date(startDate),
      ).format('hh:mm a')} - ${getStringDate(
        endDate,
        'MMM D',
      )} ${moment(new Date(endDate)).format('hh:mm a')}`;
    }
    return temp
  } else {
    let temp = ''
    const sfDate = moment(new Date(startDate)).format('YYYY-MM-DD');
    if (sfDate) {
      temp = getStringDate(startDate, 'MMM D');
      temp += ` ${moment(new Date(startDate)).format(
        'hh:mm a',
      )}`;
    }
    return temp
  }
}
function getStringDate(date, format) {
  if (date !== '') {
    // if (date.indexOf('T') > 0 && date.indexOf('Z') < 0) {
    //   date += 'Z';
    // }
    const requestDt = new Date(date);
    const todayDate = new Date().setHours(0, 0, 0);
    if (
      moment(todayDate).format('YYYY/MM/DD') ===
      moment(requestDt).format('YYYY/MM/DD')
    ) {
      return 'Today';
    }
    if (todayDate > requestDt) {
      const diffTime = Math.abs(todayDate - requestDt);

      const diffDays = Math.ceil(
        parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)),
      )
      if (diffDays === 1) {
        return 'Yest';
      }
    }
    if (todayDate < requestDt) {
      const diffTime = Math.abs(requestDt - todayDate);
      const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

      if (diffDays === 1) {
        return 'Tom';
      }
    }
    return moment(requestDt).format(format || 'ddd, D MMM');
  }
  return '';
}

export function getDayAccordingToLimitsDate (limit) {
  return new Promise((resolve, reject) => {
    console.log('reject', reject)
    let currentDate = new Date()
    let currentDate1 = new Date()
    let y = ''
    let m = ''
    let obj = {
      startDate: currentDate,
      endDate: currentDate1
    }
    const d = new Date()
    const first = currentDate.getDate() - currentDate.getDay()
    switch (limit) {
      case 'today':
        break
      case 'yesterday':
        obj.startDate = new Date(currentDate.setDate(currentDate.getDate() - 1))
        obj.endDate = new Date(currentDate)
        break
      case 'thisWeek':
        // let first = currentDate.getDate() - currentDate.getDay()
        obj.startDate = new Date(currentDate.setDate(first))
        console.log('this.week', first)
        obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 6))
        break
      case 'pre7Days':
        y = currentDate.setDate(currentDate.getDate() - 6)
        obj.startDate = new Date(y)
      break
      case '4Days':
        obj.startDate = new Date(currentDate.setDate(first))
        console.log('this.week', first)
        obj.endDate = new Date(currentDate.setDate(new Date(obj.startDate).getDate() + 3))
      break
      case 'lastWeek':
        obj.endDate = new Date(d.setTime(d.getTime() - (d.getDay() ? d.getDay() : 7) * 24 * 60 * 60 * 1000))
        obj.startDate = new Date(d.setTime(d.getTime() - 6 * 24 * 60 * 60 * 1000))
        break
      case 'thisMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, m, 1)
        obj.endDate = new Date(y, m + 1, 0)
        break
      case 'lastMonth':
        y = currentDate.getFullYear()
        m = currentDate.getMonth()
        obj.startDate = new Date(y, (m - 1), 1)
        obj.endDate = new Date(y, m, 0)
        break
      case 'next30Days':
        y = currentDate1.setDate(currentDate.getDate() + 30)
        obj.endDate = new Date(y)
        break
      case 'next60Days':
        y = currentDate1.setDate(currentDate.getDate() + 59)
        obj.endDate = new Date(y)
        break
      case '15Days':
        y = currentDate.setDate(currentDate.getDate() - 14)
        obj.startDate = new Date(y)
        break
      case 'last60Days':
        y = currentDate.setDate(currentDate.getDate() - 59)
        obj.startDate = new Date(y)
        break
      case 'last30Days':
        y = currentDate.setDate(currentDate.getDate() - 29)
        obj.startDate = new Date(y)
        break
      case 'last90Days':
        y = currentDate.setDate(currentDate.getDate() - 89)
        obj.startDate = new Date(y)
        break
      case 'last120Days':
        y = currentDate.setDate(currentDate.getDate() - 119)
        obj.startDate = new Date(y)
        break
      case 'last180Days':
        y = currentDate.setDate(currentDate.getDate() - 179)
        obj.startDate = new Date(y)
        break
    }
    obj.startDate = moment(new Date(obj.startDate.setHours(0, 0, 0, 0))).format('YYYY-MM-DD')
    obj.endDate = moment(new Date(obj.endDate.setHours(23, 59, 59, 999))).format('YYYY-MM-DD')
    resolve(obj)
  })
}
