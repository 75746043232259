<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-2 py-4 rounded-xl absolute -top-11 w-full flex items-center justify-between">
          <div>
            Task list ({{totalCount}})
          </div>
        </div>
      </div>
      <!-- <div class="mt-6 flex items-center justify-between">
        <div class="flex flex-wrap mb-0 items-center">
          <button
            @click="buttonHandler('next30Days')"
            :class="`${isSelected === 'next30Days' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space mr-2">
            Next 30 Days
          </button>
          <button
            @click="buttonHandler('thisWeek')"
            :class="`${isSelected === 'thisWeek' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space mr-2">
            This Week
          </button>
          <button
            @click="buttonHandler('custom')"
            :class="`${isSelected === 'custom' ? 'bg-info border-info' : 'bg-white border-gray2'}`"
            class="py-1.5 px-3 text-sm border hover:text-primary text-text1 rounded-xl filter_list_btn bottom_space">
            Custom
          </button>
          <div class="card bg-white p-2 rounded-xl m-1 ml-2">
            <div class="flex justify-between align-center pa-2" :class="isBarDateSelection ? 'text-primary' : 'text-text2'">
              <span @click="changeDate('pre');isBarDateSelection = true;isSelected = ''"><i class="fas fa-chevron-left  heading-5 cursor-pointer mx-3" ></i></span>
              <p class="date_time_picker__header  cursor-pointer" @click="openSelDatePicker = true;isBarDateSelection = true;isSelected = ''">{{filterObject.startDate | shiftBarDateFilter}}</p>
              <span @click="changeDate('next');isBarDateSelection = true;isSelected = ''" :class="isDiableNext ? 'cursor-not-allowed text-text2' : ' cursor-pointer'" ><i class="fas fa-chevron-right heading-5   mx-3" ></i></span>
            </div>
            <div v-if="openSelDatePicker" class="popup_overlay">
              <div style="min-height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                <DateTimePickerCustom
                    v-model="filterObject.startDate"
                    v-if="openSelDatePicker"
                    :showDate="true"
                    :title="'Select Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    :roundedCard="true"
                    :maxDate="getEndDate"
                    :fromProject="false"
                    @closeDateTimepickerDialog="changeDateforSel">
                </DateTimePickerCustom>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="p-2 pb-0">
        <div class="flex flex-wrap mb-2 items-center mt-4">
          <div>
            <StatusBtn class=" whitespace-nowrap" @buttonAction="changeValue(true)" :selected="filterObject.isCOmplted" :btnText="'Open'" />     
            <StatusBtn class=" whitespace-nowrap" @buttonAction="changeValue(false)" :selected="!filterObject.isCOmplted" :btnText="'Completed'" />     
          </div>
          <div class="card bg-white p-2 rounded-xl m-1 ml-2">
            <div class="flex justify-between align-center pa-2" :class="isBarDateSelection ? 'text-primary' : 'text-text2'">
              <span @click="changeDate('pre');isBarDateSelection = true;isSelected = ''"><i class="fas fa-chevron-left  heading-5 cursor-pointer mx-3" ></i></span>
              <p class="date_time_picker__header  cursor-pointer" @click="openSelDatePicker = true;isBarDateSelection = true;isSelected = ''">{{filterObject.startDate | shiftBarDateFilter}}</p>
              <span @click="changeDate('next');isBarDateSelection = true;isSelected = ''" ><i class="fas fa-chevron-right heading-5 cursor-pointer  mx-3" ></i></span>
            </div>
            <div v-if="openSelDatePicker" class="popup_overlay">
              <div style="min-height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                <DateTimePickerCustom
                    v-model="filterObject.startDate"
                    v-if="openSelDatePicker"
                    :showDate="false"
                    :title="'Select Date'"
                    :showOnlyDate="true"
                    :showStartDate="true"
                    :roundedCard="true"
                    :maxDate="getEndDate"
                    :fromProject="false"
                    @closeDateTimepickerDialog="changeDateforSel">
                </DateTimePickerCustom>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mb-2 items-center justify-between mt-4">
          <!-- <div class="pr-2  text-gray4 heading-5">Entity:</div> -->
          <div class="flex overflow-auto mb-2 items-center">
              <div class="pr-2 text-gray4 heading-5">Employee:</div>
              <div class="" v-for="(status, index) in empStatusList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
                <StatusBtn class=" whitespace-nowrap my-1" @buttonAction="toggleActiveAndInactiveB(index)" :selected="status.selected" :btnText="status.fullName" :lastInlist="empStatusList.length === index + 1"/>     
              </div>
            </div>
            <div class="mb-4">
                <Button @buttonAction="createInvoice(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Create Task'"/>
              </div>
          </div>
          <div class="flex flex-wrap justify-between items-center ">
            <div class="w-72 mb-4">
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Task...`"/>
            </div>
            <!-- <div class="flex overflow-auto mb-2 items-center">
              <div class="" v-for="(status, index) in filterList" :key="index" :class="`${index === 0 ? 'left-0 sticky bg-white' : ''}`">
                <StatusBtn class=" whitespace-nowrap my-1" @buttonAction="toggleActiveAndInactiveA(index)" :selected="status.isSelected" :btnText="status.value" :lastInlist="filterList.length === index + 1"/>     
              </div>
            </div> -->
          </div>
        </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="shiftListing.length > 0">
          <div v-for="(data, index) in shiftListing" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="showVisitDetail(data)">
            <div class="flex items-center justify-between">
              <div>
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <div class="flex justify-between items-center">
                  <p class="text-gray4 heading-5 overFlowParaA">{{data.displayText}}</p>
                </div>
                <div class="flex justify-between items-center">
                  <p class="text-text2 heading-5 py-1 font-semibold whitespace-pre-line">{{data.taskTitle}}</p>
                  <p class="text-primary heading-5" v-if="data.runningTimer > 0"><i class="fas fa-circle-play"></i></p>
                </div>
                <p class="text-gray4 heading-5">{{data.taskDate | formatForDatePicker}}</p>
                <div v-for="(item, index) in data.assignToList" :key="index" class="chip px-2 mx-1 mt-1">
                  <span class="text-gray4" style="font-size:12px"> {{item.userFirstName}} {{item.userLastName}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
        <div class=" mt-4">
          <Pagination
            :totalDataCount="totalCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="btnDatePicker" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker" :isShowMore="false"/>
      </div>
    </div>
  </div>
</template>
<script>
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
import deboucneMixin from '@/mixins/debounce.js'
// import DateTimePickerCustom from '@/View/components/dateTimepicker'
import moment from 'moment'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from "@/api/MyJobApp.js";
import NoRecords from '@/View/components/noRecords.vue'
import {getDisplayText} from '@/utils/convertDateAndTime.js'
export default {
components: {
  NoRecords,
  Pagination,
  StatusBtn,
  SearchTextInput,
  Button,
  DateTimePickerCustom,
  CustomDateSelector,
},
props: [],
mixins: [deboucneMixin],
data () {
  return {
    searchForRequest: '',
    empStatusList: [],
    filterList: [
      {value: 'Repeated', Id: true, isSelected: true},
      {value: 'Non-Repeated', Id: false, isSelected: true}
    ],
    mobileView: false,
    isOpenDetail: false,
    isBarDateSelection: true,
    openSelDatePicker: false,
    isDiableNext: false,
    isSelected: '',
    totalCount: 0,
    showCustomDatepicker: false,
    TempStartDate: '',
    sendEmailReq: false,
    sendEmaildetailReq: false,
    showMenuList: false,
    TempEndDate: '',
    specidifTimeSheet: [],
    totalBreakCount: 0,
    totalShiftCount: 0,
    resetPaginationValues: false,
    activeUer: 0,
    btnDatePicker: {
      startDate: '',
      endDate: '',
      exportData: false,
      selectedEmpId: '',
      selectedValue: '',
    },
    filterObject: {
      start: 0,
      length: 20,
      startDate: '',
      endDate: '',
      shortBy: '',
      sortOrder: '',
      customerId: 0,
      repeated: '',
      exportData: false,
      selectedEmpId: '',
      isRepeat: '',
      isCOmplted: false,
    },
    shiftListing: [],
    selectedObj: {},
    titleObj: [
      {title: 'Name', icon: '', sortName: 'firstName'},
      {title: 'Total Time(Min)', icon: '', sortName: 'totalTimeInMin'},
      {title: 'Start Time', icon: '', sortName: 'shiftStartTime'},
      {title: 'End Time', icon: '', sortName: 'shiftEndTime'},
      {title: 'Total Breaks', icon: '', sortName: 'totalBreak'},
    ] 
  }
},
created() {
    window.addEventListener("resize", this.resizeWindowHandler);
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo')).userDetailId
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
computed: {
    getEndDate () {
      var endDate = new Date()
      return endDate.toISOString().slice(0, 10)
    }
  },
mounted () {
  this.getListDebounc = this.debounce(function () {
    this.resetPaginationValues = true
  }, 500)
  let Today = new Date()
  this.filterObject.startDate = new Date((Today).setHours(0, 0, 0, 0)).toUTCString()
  this.filterObject.endDate = new Date((Today).setHours(24, 0, 0, 0)).toUTCString()
  this.btnDatePicker.startDate = new Date((Today).setHours(0, 0, 0, 0)).toUTCString()
  this.btnDatePicker.endDate = new Date((Today).setHours(24, 0, 0, 0)).toUTCString()
  this.getEmployeeList()
  this.getTaskList(false)
},
watch: {
},
methods: {
  changeDate (value) {
    const fObj = this.filterObject
    let fDate = fObj.startDate === '' ? new Date() : new Date(fObj.startDate);
    let newDateT = new Date();
    if (value === 'pre') {
      newDateT = new Date(fDate.setDate(fDate.getDate() - 1));
      fObj.startDate = new Date(newDateT.setHours(0, 0, 0, 0)).toUTCString()
      fObj.endDate = new Date(newDateT.setHours(24, 0, 0, 0)).toUTCString()
    } else {
      newDateT = new Date(fDate.setDate(fDate.getDate() + 1));
      fObj.startDate = new Date(newDateT.setHours(0, 0, 0, 0)).toUTCString();
      fObj.endDate = new Date(newDateT.setHours(24, 0, 0, 0)).toUTCString();
    }
    if (moment(new Date()).format('YYYY-MM-DD') === moment(new Date(fObj.startDate)).format('YYYY-MM-DD')) {
      this.isDiableNext = true
    } else {
      this.isDiableNext = false
    }
    this.getTaskList(false)
  },
  searchCall (data) {
    this.searchForRequest = data
    this.getListDebounc()
  },
  sendEmailReqCall (from) {
    if (from === 'detail') {
      this.sendEmaildetailReq = true
    } else {
      this.sendEmailReq = true
    }
  },
  createInvoice () {
    this.$router.push({name: 'AddNewTask', params: {taskId: 0}})
  },
  closeBtn () {
    this.sendEmailReq = false
    this.sendEmaildetailReq = false
  },
  changeValue (data) {
    console.log('data', data)
    this.filterObject.isCOmplted = data
    this.getTaskList(false)
  },
  buttonHandler (value) {
    this.isBarDateSelection = false
    this.isSelected = value
    if (value !== 'custom') {
      getDayAccordingToLimits(value).then(val => {
        this.btnDatePicker.startDate = val.startDate
        this.btnDatePicker.endDate = val.endDate
        this.getTaskList(false)
        this.btnDatePicker.selectedValue = ''
      })
    } else {
      this.showCustomDatepicker = true
    }
  },
  customDateListener (data) {
    this.showCustomDatepicker = false
    if (data !== null) {
      this.btnDatePicker.startDate = data.startDate
      this.btnDatePicker.endDate = data.endDate
      this.btnDatePicker.selectedValue = data.selectedValue
    }
    this.getTaskList(false)
  },
  changeDateforSel () {
    this.openSelDatePicker = false
    let temp = new Date(this.filterObject.startDate)
    this.filterObject.startDate = new Date(temp.setHours(0, 0, 0, 0)).toUTCString();
    this.filterObject.endDate = new Date(temp.setHours(24, 0, 0, 0)).toUTCString();
    // if (moment(new Date()).format('YYYY-MM-DD') === moment(new Date(this.filterObject.startDate)).format('YYYY-MM-DD')) {
    //   this.isDiableNext = true
    // } else {
    //   this.isDiableNext = false
    // }
    this.isSelected = ''
    this.btnDatePicker.selectedValue = ''
    this.getTaskList(false)
  },
  getLeadDataFromPagination (data) {
    this.filterObject.start = data.offset
    this.filterObject.length = data.limit
    this.getTaskList(false)
  },
  showVisitDetail (data) {
    this.$router.push({name: 'TaskDetail', params: {taskId: data.taskId}, query: {date: data.taskDate}})
  },
  getDynamicTimeTitle () {
    const requestDt = new Date(this.TempStartDate).setHours(0, 0, 0);
    const todayDate = new Date().setHours(0, 0, 0);
    if (
      moment(todayDate).format('YYYY/MM/DD') ===  moment(requestDt).format('YYYY/MM/DD')
    ) {
      return `Today`;
    } else if (todayDate > requestDt) {
      const diffTime = Math.abs(todayDate - requestDt);

      const diffDays = Math.ceil(
        parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)),
      );

      if (diffDays === 1) {
        return `Yesterday`;
      } else {
        return moment(this.TempStartDate).format('DD MMM, YY') +' to '+ moment(this.TempEndDate).format('DD MMM, YY')
      }
    }
  },
  getTaskList() {
    this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
    this.TempStartDate = ''
    this.TempEndDate = ''
    if (!this.isBarDateSelection) {
      this.TempStartDate = moment(new Date(this.btnDatePicker.startDate)).format('YYYY-MM-DD')
      this.TempEndDate = moment(new Date(this.btnDatePicker.endDate)).format('YYYY-MM-DD')
    } else {
      this.TempStartDate =  moment(new Date(this.filterObject.startDate)).format('YYYY-MM-DD')
      this.TempEndDate =  moment(new Date(this.filterObject.endDate)).format('YYYY-MM-DD')
    }
    // this.filterObject.isRepeat = ''
    // let count = 0
    // for (let index = 0; index < this.filterList.length; index++) {
    //   if (this.filterList[index].isSelected) {
    //     count ++
    //   }
    // }
    // if (count === this.filterList.length) {
    //   this.filterObject.isRepeat = ''
    //   this.filterList.forEach(element => {
    //     element.isSelected = true
    //   });
    // } else {
    //   this.filterList.map((data) => {
    //     if (data.isSelected) {
    //       this.filterObject.isRepeat = data.Id
    //     }
    //   })
    // }
    MyJobApp.GetTaskListingForList(
      this.filterObject.start,
      this.filterObject.length,
      this.searchForRequest,
      this.filterObject.shortBy,
      this.filterObject.sortOrder,
      this.filterObject.customerId,
      this.TempStartDate,
      this.TempEndDate,
      this.filterObject.isRepeat,
      this.filterObject.selectedEmpId,
      this.filterObject.isCOmplted,
      // '2021-12-31',
      // '2024-01-01',
      (response) => {
        this.resetPaginationValues = false
        let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
        for (let index = 0; index < temp.length; index++) {
          if (temp[index].endTime !== '' && temp[index].startTime !== '') {
            temp[index].displayText = getDisplayText(temp[index].startTime, temp[index].endTime)
          } else {
           temp[index].displayText = '--'
          }
        }
        this.shiftListing = temp
        this.totalCount = response.Data.count
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      },
      (err) => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
        this.$store.dispatch("SetLoader", { status: false, message: "" });
      }
    );
  },
  resizeWindowHandler() {
    if (window.innerWidth < 1000) {
      this.mobileView = true
    } else {
      this.mobileView = false
    }
  },
  toggleActiveAndInactiveA (index) {
      this.filterList[index].isSelected = !this.filterList[index].isSelected
      this.filterObject.isRepeat = ''
      let count = 0
      let count2 = 0
      for (let index = 0; index < this.filterList.length; index++) {
        if (this.filterList[index].isSelected) {
          count ++
        }
        if (!this.filterList[index].isSelected) {
          count2 ++
        }
      }
      console.log('count', count)
      console.log('count2', count2)
      if (count === this.filterList.length || count2 === this.filterList.length) {
        this.filterObject.isRepeat = ''
        this.filterList.forEach(element => {
          element.isSelected = true
          console.log('element', element)
        });
      } else {
        this.filterList.map((data) => {
          if (data.isSelected) {
            this.filterObject.isRepeat = data.Id
          }
        })
      }
      this.resetPaginationValues = true
    },
  toggleActiveAndInactiveB (index) {
      let filter = []
      if (this.empStatusList[index].fullName.toLowerCase() === 'all') {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      } else {
        this.empStatusList[index].selected = !this.empStatusList[index].selected
        this.filterObject.selectedEmpId = []
        this.empStatusList.forEach((a, id) => {
          if (this.empStatusList[0].selected) {
            if (id !== index && id !== 0) {
              a.selected = false
            } else {
              if (id !== 0) {
                a.selected = true
                this.filterObject.selectedEmpId.push(a.userDetailId)
              }
            }
          } else {
            if (a.selected) {
              filter.push(a)
              this.filterObject.selectedEmpId.push(a.userDetailId)
            }
          }
        })
        this.empStatusList[0].selected = false
      }
      if (filter.length === this.empStatusList.length - 1) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      let arry = this.empStatusList.filter(a => {
        return a.selected === false
      })
      if (arry.length === this.empStatusList.length) {
        this.empStatusList[0].selected = true
        this.filterObject.selectedEmpId = []
        for (let index = 0; index < this.empStatusList.length; index++) {
          this.empStatusList[index].selected = true
        }
      }
      this.resetPaginationValues = true
      this.getTaskList(false)
    },
    getEmployeeList () {
    MyJobApp.getEmployeeList(
      'active',
      response => {
        let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
        for (let index = 0; index < temp.length; index++) {
          temp[index].selected = true
          temp[index].fullName = temp[index].firstName + temp[index].lastName.substr(0, 1)
        }
        const newFirstElement = {
          userDetailId: 0,
          fullName: "All",
          selected: true
        }
        this.empStatusList = [newFirstElement].concat(temp)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  },
}
}
</script>
<style scoped>
.table_col {
  min-width: 130px !important;
  width: 200px !important;
  max-width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.chip {
  display: inline-block;
  background-color: #dfdede;
  border-radius: 10px;
}
</style>
